<!--指标库设置  -->
<template>
	<div>
		<div class="child-top">
			<div class="input-from">
				<el-select class="last-child" clearable filterable placeholder="请选择品牌" v-model="brandSelect" @change="deviceChange">
					<el-option v-for="item in brandList" :label="item.name" :value="item.uuid" :key="item.uuid"></el-option>
				</el-select>

				<el-select class="last-child" clearable filterable placeholder="请选择设备型号" v-model="statusSelect" @change="deviceChange">
					<el-option v-for="(item, idx) in modelNumList" :label="item.model_num" :value="item.model_num" :key="'modelNum' + idx"></el-option>
				</el-select>

				<el-input
					@keyup.enter.native="getData(true)"
					class="left-child-input left-child-input-content"
					clearable
					suffix-icon="iconfont iconsousuo"
					placeholder="请输入设备名称"
					style="width: 200px;"
					v-model="searchValue"
				>
				</el-input>
				<el-button @click="getData(true)" slot="append" class="searchBtn" round>搜索</el-button>
			</div>
			<div class="operation-button">
				<el-button @click="add_btn" class="new_btn" round>
					<span>新增</span>
				</el-button>
				<span class="_ret" @click="reback">
					<i class="iconfont iconfanhui"></i>
					返回
				</span>
			</div>
		</div>

		<div style="background: #fff">
			<el-table v-loading="tableLoading" class="content-test-table" :data="tableList" style="width: 100%">
				<el-table-column label="序号" prop="order_no" show-overflow-tooltip type="index" width="60" align="center">
					<template slot-scope="scope">{{ (page - 1) * 10 + scope.$index + 1 }}</template>
				</el-table-column>
				<el-table-column label="设备名称" prop="name" show-overflow-tooltip align="center"> </el-table-column>
				<el-table-column label="品牌" prop="brand_name" show-overflow-tooltip align="center"></el-table-column>
				<el-table-column label="型号" prop="model_num" show-overflow-tooltip align="center"></el-table-column>
				<el-table-column label="序列号" prop="serial_num" show-overflow-tooltip align="center"></el-table-column>
				<!-- <el-table-column label="状态" prop="status" show-overflow-tooltip align="center">
					<template slot-scope="scope">
						{{ scope.row.status == 1 ? '正常' : scope.row.status == 2 ? '维修' : '报废' }}
					</template>
				</el-table-column> -->
				<el-table-column label="图片" prop="year" show-overflow-tooltip align="center">
					<template slot-scope="scope">
						<img :src="scope.row.img_host + scope.row.img_path" alt="" />
					</template>
				</el-table-column>
				<el-table-column label="创建日期" prop="create_time" show-overflow-tooltip align="center"></el-table-column>
				<el-table-column label="操作" width="200">
					<template slot-scope="scope">
						<!-- :disabled="$store.getters.permissionsStr('编辑') || scope.row.edit_flag !== 1" -->
						<!-- :class="scope.row.edit_flag == 1 ? 'blueColor right20' : 'disColor right20'" -->
						<el-button class="blueColor right20" @click="postEdit(scope.row)" size="medium" type="text">编辑</el-button>

						<el-button class="yellowColor right20" size="medium" type="text" @click="postEdit(scope.row, true)" v-show="scope.row.uuid">查看</el-button>

						<!-- :disabled="$store.getters.permissionsStr('删除') || scope.row.edit_flag !== 1" -->
						<!-- :class="scope.row.edit_flag == 1 ? 'redColor right20' : 'disColor right20'"  -->
						<el-button class="redColor right20" @click="rowDelete(scope.row.uuid)" size="medium" type="text">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				:current-page.sync="page"
				:page-size="page_size"
				:total="total"
				@current-change="handleCurrentChange"
				class="table-pagination"
				layout="total, jumper,  ->, prev, pager, next"
			></el-pagination>
		</div>

		<el-dialog :title="editShowTitle" :visible.sync="dialogVisible" center width="70%" :show-close="false" :close-on-click-modal="false">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<div class="flex_div">
					<el-form-item label="设备名称" prop="name">
						<el-input v-model="ruleForm.name" style="width: 90%;" placeholder="请输入设备名称" :disabled="look"></el-input>
					</el-form-item>
					<el-form-item label="品牌" prop="brand_uuid">
						<!-- <el-input v-model="ruleForm.brand" style="width: 90%;"></el-input> -->
						<el-select clearable placeholder="请选择品牌" v-model="ruleForm.brand_uuid" :disabled="look">
							<el-option v-for="item in brandList" :label="item.name" :value="item.uuid" :key="item.uuid"></el-option>
						</el-select>
						<span class="addBrand" @click="add_brand" v-if="!look">新增品牌</span>
					</el-form-item>
				</div>
				<div class="flex_div">
					<el-form-item label="型号" prop="model">
						<el-input v-model="ruleForm.model" style="width: 90%;" placeholder="请输入设备型号" :disabled="look"></el-input>
					</el-form-item>
					<el-form-item label="序列号" prop="sn">
						<el-input v-model="ruleForm.sn" style="width: 90%;" placeholder="请输入设备序列号" :disabled="look"></el-input>
					</el-form-item>
				</div>
				<div class="flex_div">
					<!-- <el-form-item label="状态" prop="status">
						<el-select class="last-child" clearable placeholder="请选择设备状态" v-model="ruleForm.status" :disabled="look">
							<el-option label="正常" :value="1"></el-option>
							<el-option label="维修" :value="2"></el-option>
							<el-option label="报废" :value="3"></el-option>
						</el-select>
					</el-form-item> -->
					<el-form-item label="备注" prop="demo">
						<el-input v-model="ruleForm.demo" type="textarea" :rows="2" style="width: 96%;" placeholder="请输入备注" :disabled="look"></el-input>
					</el-form-item>
				</div>
				<el-form-item label="图片" prop="imageUrl">
					<el-upload
						:action="'/p/equipment_hardware/uploadFile'"
						:class="{ hide_box: uploadBtnShow }"
						list-type="picture-card"
						:file-list="fileList"
						:limit="1"
						:on-success="successFile"
						:on-preview="handlePictureCardPreview"
						:on-remove="handleRemove"
						:on-exceed="handleExceed"
						:disabled="look"
						:before-upload="beforeAvatarUpload"
					>
						<i class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="" prop="">
					<div class="el-upload__tip">只能上传jpg/jpeg/png文件，且不超过5M</div>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" v-show="!look" @click="save_btn">确 定</el-button>
				<el-button @click="cancel_btn">取 消</el-button>
			</span>

			<el-dialog width="30%" center title="新增品牌" :visible.sync="innerVisible" append-to-body :show-close="false" :close-on-click-modal="false">
				<div class="brand_view"><i>*</i> 品牌: <el-input v-model="newBrandName" style="width: 80%"></el-input></div>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="inner_save">保 存</el-button>
					<el-button @click="inner_cancel">取 消</el-button>
				</span>
			</el-dialog>

			<el-dialog :visible.sync="imageDialogVisible" append-to-body class="image_dialog">
				<img :src="dialogImageUrl" alt="" />
			</el-dialog>
		</el-dialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			serverurl: window.server,
			tableLoading: false, //loading
			tableList: [],
			brandSelect: '',
			page_size: 10,
			total: 0,
			page: 1,
			searchDate: '',
			searchValue: '',
			newBrandName: '',
			dialogVisible: false,
			innerVisible: false,
			look: false,
			ruleForm: {
				imageUrl: '',
				demo: '',
			},
			rules: {
				name: { required: true, message: '请输入设备名称', trigger: 'blur' },
				brand_uuid: { required: true, message: '请选择品牌', trigger: 'change' },
				model: { required: true, message: '请输入型号', trigger: 'blur' },
				sn: { required: true, message: '请输入序列号', trigger: 'blur' },
				imageUrl: { required: true, message: '请选择图片', trigger: 'change' },
				// status: { required: true, message: '请选择设备状态', trigger: 'change' },
			},
			imageUrl: '',
			brandList: [],
			statusSelect: '',
			editShow: false,
			editShowTitle: '',
			fileList: [],
			dialogImageUrl: '',
			imageDialogVisible: false,
			modelNumList: [],
			cate_uuid: '',
			uploadBtnShow: false,
		}
	},
	mounted() {
		this.cate_uuid = this.$route.query.cate_uuid
		this.getData()
		this.getBrandList()
		this.getModelNumList()
	},

	methods: {
		getBrandList() {
			this.$axios.post('/p/equipment_hardware/brandList').then(res => {
				if (res.data.code == 0) {
					this.brandList = res.data.data
				}
			})
		},
		getModelNumList() {
			this.$axios.post('/p/equipment_hardware/modelNumList').then(res => {
				if (res.data.code == 0) {
					this.modelNumList = res.data.data
				}
			})
		},
		add_brand() {
			this.innerVisible = true
		},
		add_btn() {
			this.editShow = false
			this.dialogVisible = true
			this.uploadBtnShow = false
			this.look = false
			this.editShowTitle = '新增设备'
		},
		postEdit(rows, type) {
			this.dialogVisible = true
			this.uploadBtnShow = true
			this.editShow = true
			this.look = false
			this.editShowTitle = '编辑设备'

			if (type) {
				this.look = true
				this.editShowTitle = '查看设备'
			} else {
				this.look = false
			}
			this.$axios
				.post(
					'/p/equipment_hardware/read',
					this.$qs({
						uuid: rows.uuid,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.ruleForm = {
							uuid: res.data.data.uuid,
							name: res.data.data.name,
							brand_uuid: res.data.data.brand_uuid,
							model: res.data.data.model_num,
							sn: res.data.data.serial_num,
							// status: res.data.data.status,
							demo: res.data.data.demo,
							imageUrl: res.data.data.img_path,
						}
						if (res.data.data.img_path) {
							this.fileList = [
								{
									name: 'image',
									url: res.data.data.img_host + res.data.data.img_path,
								},
							]
						}
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
		},
		save_btn() {
			this.$refs['ruleForm'].validate(valid => {
				if (valid) {
					let formData = new FormData()
					formData.append('name', this.ruleForm.name)
					formData.append('brand_uuid', this.ruleForm.brand_uuid)
					formData.append('model_num', this.ruleForm.model)
					formData.append('serial_num', this.ruleForm.sn)
					// formData.append('status', this.ruleForm.status)
					formData.append('demo', this.ruleForm.demo)
					formData.append('img_path', this.ruleForm.imageUrl)
					formData.append('type', 2)
					formData.append('cate_uuid', this.$route.query.cate_uuid)
					var url = '/p/equipment_hardware/add'
					if (this.editShow) {
						formData.append('uuid', this.ruleForm.uuid)
						url = '/p/equipment_hardware/update'
					}
					this.$axios.post(url, formData).then(res => {
						if (res.data.code == 0) {
							this.$message({
								type: 'success',
								message: res.data.message,
							})
							this.dialogVisible = false
							this.uploadBtnShow = false
							this.$refs['ruleForm'].resetFields()
							this.getData()
							this.getModelNumList()
							this.ruleForm = {}
							this.fileList = []
						} else {
							this.$message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
		rowDelete(rows) {
			//删除数据
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				closeOnClickModal: false,
				cancelButtonClass: '',
				center: true,
			}).then(() => {
				this.$axios
					.post(
						'/p/equipment_hardware/delete',
						this.$qs({
							uuids: rows,
						})
					)
					.then(res => {
						if (res.data.code == 0) {
							this.$message({
								type: 'success',
								message: res.data.message,
							})
							this.getData()
						} else {
							this.$message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
			})
		},
		cancel_btn() {
			this.$refs['ruleForm'].resetFields()
			this.dialogVisible = false
			this.uploadBtnShow = false
			this.ruleForm = {}
			this.fileList = []
		},
		inner_save() {
			if (!this.newBrandName) {
				return this.$message({
					type: 'warning',
					message: '请输入品牌名称',
				})
			}
			this.$axios
				.post(
					'/p/equipment_hardware/addBrand',
					this.$qs({
						name: this.newBrandName,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.newBrandName = ''
						this.innerVisible = false
						this.getBrandList()
						this.$message({
							type: 'success',
							message: res.data.message,
						})
					}
				})
		},
		inner_cancel() {
			this.newBrandName = ''
			this.innerVisible = false
		},
		deviceChange() {
			this.page = 1
			this.getData()
		},
		//请求列表数据
		getData() {
			this.$axios
				.post(
					'/p/equipment_hardware/lists',
					this.$qs({
						page: this.page,
						page_size: this.page_size,
						brand_uuid: this.brandSelect,
						name: this.searchValue,
						// status: this.statusSelect,
						type: 2,
						model_num: this.statusSelect,
						cate_uuid: this.cate_uuid,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.tableList = res.data.data.rows
						this.total = res.data.data.total
						this.tableLoading = false
					}
				})
		},

		handleCurrentChange(val) {
			this.page = val
			this.getData()
		},

		handleClose() {},
		handleRemove(file) {
			this.uploadBtnShow = false
			this.ruleForm.imageUrl = ''
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url
			this.imageDialogVisible = true
		},

		successFile(response, file, fileList) {
			this.uploadBtnShow = true
			this.ruleForm.imageUrl = file.raw
		},

		handleAvatarSuccess(res, file) {
			this.imageUrl = URL.createObjectURL(file.raw)
		},
		beforeAvatarUpload(file) {
			console.log(file)
			const isJPG = file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png'
			const isLt2M = file.size / 1024 / 1024 < 5

			if (!isJPG) {
				this.$message.error('请上传jpg、jpeg或png格式')
			}
			if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 5MB!')
			}

			return isJPG && isLt2M
		},
		handleExceed(files, fileList) {
			this.$message.warning(`当前限制上传一个文件`)
		},
		reback() {
			this.$router.push({
				path: '/management/deviceClassification',
				query: {},
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.child-top .el-date-editor {
	border: none;
}
.disColor {
	color: #ccc !important;
}

.text {
	display: inline-block;
	margin-left: 20px;
}

/deep/.el-dialog__title {
	color: #303133;
	font-weight: 700;
}

/deep/.el-input {
	position: relative;
	font-size: 0.72917rem;
	display: inline-block;
	width: 222px;
}

/deep/.el-table th > .cell {
	padding-left: 15px;
}
/deep/.el-pagination__editor.el-input {
	width: 50px;
}

.child-top {
	margin-top: 10px;
	.input-from {
		.searchBtn {
			margin-top: 10px;
		}
	}
	.operation-button {
		// float: left;
		margin-bottom: 10px;
		margin-top: 10px;
		display: flex;
		align-items: center;
		span {
			margin: 0 10px;
			&:hover {
				cursor: pointer;
			}
		}
	}
}
.child-top .operation-button .del_btn:hover {
	background: #eb1e44;
	color: #ffffff;
}

.el-pagination {
	padding-bottom: 10px;
}

.echarts_view {
	overflow-x: auto;
	box-sizing: border-box;
	overflow-y: hidden;
	margin-bottom: 20px;
}

.echarts_content {
	width: 100%;
	height: 300px;
	white-space: nowrap;
	box-sizing: border-box;
	// margin-bottom: 1px;
	div {
		display: inline-block;
		background: #fff;
		margin: 0 10px;
		width: 400px;
		height: 300px;
		box-sizing: border-box;
	}
}

.echarts_content_top {
	height: 200px;
	div {
		height: 200px;
	}
}

.demo-ruleForm {
	.flex_div {
		display: flex;
		.el-form-item {
			flex: 1;
			.addBrand {
				margin-left: 20px;
				color: #409eff;
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}

// .hide_box /deep/ .el-upload--picture-card {
// 	display: none;
// }

::v-deep .hide_box .el-upload--picture-card {
	display: none;
}

.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}
.avatar {
	width: 178px;
	height: 178px;
	display: block;
}
.brand_view {
	text-align: center;
	i {
		color: #f00;
	}
}

.content-test-table {
	img {
		height: 50px;
	}
}

.image_dialog {
	/deep/ .el-dialog {
		height: 700px;
		overflow: hidden;

		.el-dialog__body {
			height: 80%;
			text-align: center;
			img {
				width: 100%;
				// height: 100%;
			}
		}
	}
}
</style>
